<template>
  <v-app>
    <v-main>
      <v-container grid-list-xl>
        <v-layout row wrap justify-center class="my-5">
          <v-flex xs12 sm4 offset-sm1>
            <v-card v-if="success === null" class="elevation-0 transparent">
              <v-card-title primary-title class="layout justify-center">
                <div class="headline">Email validation...</div>
              </v-card-title>
              <v-card-text class="layout justify-center">
                <div style="min-height: 4px;">
                  <v-progress-linear :active="true"></v-progress-linear>
                </div>
              </v-card-text>
            </v-card>
            <v-card v-if="success === false" class="elevation-0 transparent">
              <v-card-title primary-title class="layout justify-center">
                <div class="headline">Verification failed.</div>
              </v-card-title>
              <v-card-text class="layout justify-center">
                <div class="text-center subheading">{{message}}</div>
              </v-card-text>
            </v-card>
            <v-card v-if="success === true" class="elevation-0 transparent ma-3 text-center">
              <v-card-title primary-title>
                <p class="headline">Email verification succeeded</p>
              </v-card-title>
              <v-card-actions class="justify-center">
                <v-progress-circular :indeterminate="true" color="light-blue" />
              </v-card-actions>
              <v-card-text>
                <span
                  class="subheading text-center"
                >Thank you for your registration. You'll be redirected on login page in few seconds.</span>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
.subheading {
  font-weight: 400 !important;
}
</style>

<script>
import VERIFYEMAIL from '@/graphql/mutations/verifyEmail.gql';

export default {
  components: {
    LoginDialog: () => import('@/components/LoginDialog.vue')
  },
  data: () => ({
    success: null,
    message: ''
  }),
  methods: {
    verify () {
      const email = this.$route.params.email
      const token = this.$route.params.token
      this.$apollo
        .mutate({
          mutation: VERIFYEMAIL,
          variables: {
            email,
            token
          }
        })
        .then(result => {
          this.success = result.data.verifyEmail.success
          this.message = 'The link you provided is invalid. Please try again.'
          if (this.success) {
            setTimeout(() => {
              this.$router.push('/?login=true')
            }, 2000)
          }
        })
        .catch(e => {
          this.$raven.captureException(e)
          this.success = false
          this.message = 'Something went wrong ! We get notifyed by this problem but feel free to contact us if the problem persists.'
        })
    }
  },
  mounted () {
    this.verify()
  }
}
</script>
